import { LanguageText } from "../../utils/LanguageText";
import Styles from "./QuickFeedback.module.css";
import QuickFeedbackButton from "./QuickFeedbackButton";

interface Props {
    isLiked: boolean | null;
    feedback: string[];
    setFeedback: React.Dispatch<React.SetStateAction<string[]>>;
}

const QuickFeedback = ({ isLiked, feedback, setFeedback }: Props) => {
    const onFeedbackClick = (feedbackText: string) => {
        setFeedback(prev => (prev.includes(feedbackText) 
            ? prev.filter(item => item !== feedbackText) 
            : [...prev, feedbackText]));
    };

    const feedbackOptions = {
        true: [
            LanguageText.QF_CORRECT,
            LanguageText.QF_EASY,
            LanguageText.QF_COMPLETE
        ],
        false: [
            LanguageText.QF_INCORRECT,
            LanguageText.QF_CONFUSING,
            LanguageText.QF_INCOMPLETE
        ]
    };

    if (isLiked === null) return null;

    const options = feedbackOptions[String(isLiked) as keyof typeof feedbackOptions];

    return (
        <div className={Styles.container}>
            {options.map(text => (
                <QuickFeedbackButton
                    key={text}
                    text={text}
                    selected={feedback.includes(text)}
                    onSelect={onFeedbackClick}
                />
            ))}
        </div>
    );
};

export default QuickFeedback;
