import { useState } from "react";
import Styles from "./LikeButton.module.css";
import Lottie from "lottie-light-react";
import likeAnimation from "../../assets/like.json";

interface Props {
    isLiked: boolean | null;
    setIsLiked: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const LikeButton = ({ isLiked, setIsLiked }: Props) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handleLikeClick = () => {
        if (isLiked == null || isLiked === false) {
            setIsLiked(true);
            setIsPlaying(true);
        } else if (isLiked === true) {
            setIsLiked(null);
        }
    };
    
    const handleAnimationComplete = () => {
        setIsPlaying(false);}

    return (
        <button id="likeButton" className={Styles.iconButton} onClick={handleLikeClick}>
            {isPlaying ? (
                <Lottie animationData={likeAnimation} loop={false} autoplay className={Styles.likeAnimation} onComplete={handleAnimationComplete} />
            ) : (
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
                    <rect width="24" height="24" rx="12" className={isLiked === true ? Styles.fillBlue : Styles.fillWhite} />
                    <path
                        className={isLiked === true ? Styles.fillWhite : Styles.iconColor}
                        d="M16.5 10C16.7667 10 17 10.1 17.2 10.3C17.4 10.5 17.5 10.7334 17.5 11V12C17.5 12.0584 17.4917 12.1209 17.475 12.1875C17.4583 12.2542 17.4417 12.3167 17.425 12.375L15.925 15.9C15.85 16.0667 15.725 16.2084 15.55 16.325C15.375 16.4417 15.1917 16.5 15 16.5H9.5V10L12.5 7.02505C12.625 6.90005 12.7729 6.82713 12.9437 6.8063C13.1146 6.78547 13.2792 6.81672 13.4375 6.90005C13.5958 6.98338 13.7125 7.10005 13.7875 7.25005C13.8625 7.40005 13.8792 7.55422 13.8375 7.71255L13.275 10H16.5ZM10.5 10.425V15.5H15L16.5 12V11H12L12.675 8.25005L10.5 10.425ZM8 16.5C7.725 16.5 7.48958 16.4021 7.29375 16.2063C7.09792 16.0105 7 15.775 7 15.5V11C7 10.725 7.09792 10.4896 7.29375 10.2938C7.48958 10.098 7.725 10 8 10H9.5V11H8V15.5H9.5V16.5H8Z"
                    />
                </svg>
            )}
        </button>
    );
};

export default LikeButton;
