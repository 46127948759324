


export const AnswerIcon = () => {
    return (
        <svg width="1.25em" height="1.25em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_623_105920)">
                <rect width="100%" height="100%" fill="white" />
                <path
                    d="M11.5012 11.3392L9.25708 12.336L11.5012 13.3328L12.5331 15.5004L13.5651 13.3328L15.8092 12.336L13.5651 11.3392L12.5331 9.17165L11.5012 11.3392ZM5.11428 9.8931L6.65402 6.63381L10.0284 5.14656L6.65402 3.65932L5.11428 0.400024L3.57453 3.65932L0.200195 5.14656L3.57453 6.63381L5.11428 9.8931Z"
                    fill="#0060A1"
                />
            </g>
            <defs>
                <clipPath id="clip0_623_105920">
                    <rect width="100%" height="100%" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

// Instead of importing the svg from svg file in /assets folder svg is used inline here as it drastically improved the load time of said svg
