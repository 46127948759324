import { ArrowSync24Filled } from "@fluentui/react-icons";
import styles from "./ClearChatButton.module.css";
import { Tooltip } from "react-tooltip";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <button id="clearChatButton" className={`${styles.container} ${className ?? ""} ${disabled ? styles.disabled : styles.enabled}`} onClick={onClick}>
            <ArrowSync24Filled aria-label="Clear chat button" data-tooltip-id="clear-chat-tooltip" data-tooltip-content="Begin nieuw gesprek" className={styles.clearChatIcon} />
            {!disabled && <Tooltip id="clear-chat-tooltip" />}
        </button>
    );
};
