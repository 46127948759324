import styles from "./FollowUpQuestionButtons.module.css";

interface FollowUpQuestionsButtonProps {
    followUpQuestions: string [] | null;
    onFollowUpQuestionClicked : (question: string) => void;
}

const FollowUpQuestionButton = ({ followUpQuestions, onFollowUpQuestionClicked }: FollowUpQuestionsButtonProps) => {    

    return (
        <>
            {followUpQuestions && followUpQuestions.length > 0 && followUpQuestions.map((question) => (
            <button
                key={question}
                className={styles.followUpQuestion}
                title={question}
                onClick={() => onFollowUpQuestionClicked(question)}
            >
                {question}
            </button>
            ))}
        </>
    );
};

export default FollowUpQuestionButton;
