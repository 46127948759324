import { LanguageText } from "../../utils/LanguageText";
import Styles from "./OpenFeedback.module.css";

interface Props {
    isLiked: boolean | null;
    isFeedbackOpen: boolean;
    setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OpenFeedback = ({ isLiked, isFeedbackOpen, setIsFeedbackOpen }: Props) => {
    const onOpenFeedback = () => {
        setIsFeedbackOpen(!isFeedbackOpen)
    };

    return isLiked != null ? <button id={"openFeedbackButton"} className={`${Styles.feedbackButton} ${isFeedbackOpen ? Styles.feedbackClicked : ''}`} onClick={onOpenFeedback}>{LanguageText.OPEN_FEEDBACK_BUTTON}</button> : null;
};

export default OpenFeedback;
