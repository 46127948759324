export class LanguageText {
    public static readonly CITATION = "Citaat";
    public static readonly EMPTY_STATE_SUBTITLE = " is mijn expertise, stel gerust je vragen.";
    public static readonly SOURCE = "Bron";
    public static readonly SOURCES = "Bronnen";
    public static readonly SHOW_SUPPORTING_CONTENT = "Toon bronnen";
    public static readonly DISCLAIMER = "AI kan fouten maken, dus controleer de bronnen!"
    public static readonly CHANGE_ASSISTANT = "Kies je assistent";
    public static readonly FEEDBACK_THANKS = "Bedankt voor je feedback!";
    public static readonly FEEDBACK_GREETING = "Was dit antwoord nuttig?";
    public static readonly QF_CORRECT = "Correct";
    public static readonly QF_EASY = "Begrijpelijk";
    public static readonly QF_COMPLETE = "Compleet";
    public static readonly QF_INCORRECT = "Incorrect";
    public static readonly QF_CONFUSING = "Verwarrend";
    public static readonly QF_INCOMPLETE = "Onvolledig";
    public static readonly OPEN_FEEDBACK_HEADER = "Hoe kunnen wij dit antwoord verbeteren?";
    public static readonly OPEN_FEEDBACK_BUTTON = "Vertel ons meer";
    public static readonly OPEN_FEEDBACK_PLACEHOLDER = "Vertel ons meer";
    public static readonly SUBMIT_FEEDBACK_BUTTON = "Versturen";
}
