import Styles from "./DislikeButton.module.css";

interface Props {
    isLiked: boolean | null;
    setIsLiked: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const DislikeButton = ({ isLiked, setIsLiked }: Props) => {
    const handleLikeClick = () => {
        if (isLiked == null || isLiked === true) {
            setIsLiked(false);
        } else if (isLiked === false)
        {
            setIsLiked(null);
        }
    };

    return (
        <button id="dislikeButton" className={Styles.iconButton} onClick={handleLikeClick}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
                <rect width="24" height="24" rx="12" className={isLiked === false ? Styles.fillBlue : Styles.fillWhite} />
                <path
                    className={isLiked === false ? Styles.fillWhite : Styles.iconColor}
                    d="M7.5 14C7.23333 14 7 13.9 6.8 13.7C6.6 13.5 6.5 13.2667 6.5 13V12C6.5 11.9417 6.50833 11.8792 6.525 11.8125C6.54167 11.7458 6.55833 11.6833 6.575 11.625L8.075 8.1C8.15 7.93333 8.275 7.79167 8.45 7.675C8.625 7.55833 8.80833 7.5 9 7.5H14.5V14L11.5 16.975C11.375 17.1 11.2271 17.1729 11.0563 17.1938C10.8854 17.2146 10.7208 17.1833 10.5625 17.1C10.4042 17.0167 10.2875 16.9 10.2125 16.75C10.1375 16.6 10.1208 16.4458 10.1625 16.2875L10.725 14H7.5ZM13.5 13.575V8.5H9L7.5 12V13H12L11.325 15.75L13.5 13.575ZM16 7.5C16.275 7.5 16.5104 7.59792 16.7063 7.79375C16.9021 7.98958 17 8.225 17 8.5V13C17 13.275 16.9021 13.5104 16.7063 13.7063C16.5104 13.9021 16.275 14 16 14H14.5V13H16V8.5H14.5V7.5H16Z"
                />
            </svg>
        </button>
    );
};

export default DislikeButton;
