import { ReactNode } from "react";
import styles from "./NoAccess.module.css";
import animationData from "../../assets/knowledge_assistant_explainer.json";
import Lottie from "lottie-light-react";
import warningLogo from "../../assets/warning.svg";

const NoAccess = ({ title, subTitle, freeTrialEnded, description }: { title: string; subTitle: string; freeTrialEnded: boolean; description: ReactNode }) => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"} />
            <div className={styles.parentContainer}>
                <div className={styles.centeredDiv}>
                    {freeTrialEnded ? (
                        <div className={styles.trialAnimationContainer}>
                            <Lottie animationData={animationData} loop={true} style={{ height: "100%", width: "100%" }} />
                        </div>
                    ) : (
                        <img src={warningLogo} alt="Warning" className={styles.warningLogo} />
                    )}
                    <h3 className={styles.title}>{title}</h3>
                    <h4 className={styles.subTitle}>{subTitle}</h4>
                    <div className={styles.description}>{description}</div>
                </div>
            </div>
        </div>
    );
};

export default NoAccess;
