import { LanguageText } from "../../utils/LanguageText";
import { useEffect, useState } from "react";
import styles from "./AnalysisPanel.module.css";
import { Constants } from "../../utils/Constants";

interface Props {
    activeCitation: string | undefined;
    footerHeight: number;
}

export const AnalysisPanel = ({ activeCitation, footerHeight }: Props) => {
    const [iframeKey, setIframeKey] = useState<number>(0);

    useEffect(() => {
        if (!window.navigator.userAgent.includes("Edge") && !window.navigator.userAgent.includes("Chrome") && !window.navigator.userAgent.includes("Safari")) {
            return;
        }
        setIframeKey(iframeKey + 1);
    }, [activeCitation]);

    return (
        <div
            style={{
                maxHeight: `calc(100vh - var(--header-height) - ${footerHeight + Constants.STANDARD_FOLLOWUPQ_HEIGHT}px)`
            }}
            className={styles.sourceContainer}
        >
            <iframe key={iframeKey} title={LanguageText.SOURCE} src={activeCitation} width="100%" />
        </div>
    );
};
