import Styles from "./QuickFeedback.module.css";

interface Props {
    text: string;
    selected: boolean;
    onSelect: (text: string) => void;
}

const QuickFeedbackButton = ({ text, selected, onSelect }: Props) => {
    return (
        <button            
            id={"quickFeedbackButton" + text}
            className={`${Styles.feedbackButton} ${selected ? Styles.feedbackClicked : ""}`}
            onClick={() => onSelect(text)}
        >
            {text}
        </button>
    );
};

export default QuickFeedbackButton;
