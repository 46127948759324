import { getCitationFilePath } from "../../api";
import styles from "./Answer.module.css";

interface AnswerCitationProps {
    index: number;
    citation: string;
    onCitationClicked: (url: string) => void;
    citationSelected: number;
    setCitationSelected: React.Dispatch<React.SetStateAction<number>>;
    answerSelected: boolean | undefined;
}

const AnswerCitation = ({ index, citation, onCitationClicked, citationSelected, setCitationSelected, answerSelected }: AnswerCitationProps) => {
    setCitationSelected(answerSelected ? citationSelected : -1);

    const handleClick = () => {
        if (citation.includes("https") || citation.includes("http")) {
            window.open(citation, "_blank");
        } else {
            onCitationClicked(getCitationFilePath(citation));
            setCitationSelected(citationSelected !== index ? index : -1);
        }
    };

    return (
        <button
            className={`${styles.citation} ${citationSelected === index ? styles.citationSelected : ""}`}
            title={citation}
            onClick={handleClick}
            aria-label="5 star rating system"
        >
            {`${index + 1}. ${citation}`}
        </button>
    );
};

export default AnswerCitation;
